.App {
  text-align: center;
  min-height: 100vh;
  min-width: 100vw;
  z-index: 1;
  background-color: white;
  color: #161f38;
}

a {
  text-decoration: none;
  color: unset;
}

button,
input[type='submit'],
input[type='reset'] {
  background: none;
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.body-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: calc(100vw - 6rem);
  height: calc(100vh - 6rem);
  padding: 3rem;
  flex-direction: column;
}

@keyframes App-logo-spin {
  /* from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  } */
}
