.footer {
  width: calc(100vw - 6rem);
  position: absolute;
  z-index: 10;
  height: 3rem;
  bottom: 0;
  padding: 2rem;
}

.footer-content {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: aquamarine; */
}
