.container {
  background: #fff;
  color: #696969;
  box-shadow: 0 4px 14px 0 rgb(0 0 0 / 10%);
  padding: 0 5rem;
  height: 2.81rem;
  line-height: 2.8rem;
  border-radius: 7px;
  font-size: 1rem;
  transition: box-shadow 0.2s ease-in-out;

  /* positioning */
  /* position: absolute;
  display: inline-block;
  top: calc(8rem);
  left: 0px;
  right: 0px; */
}

.container:hover {
  box-shadow: 0 4px 14px 0 rgb(0 0 0 / 14%);
}
