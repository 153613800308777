.gilroy-regular {
  font-family: 'GilroyMedium';
  font-size: 1.6rem;
  color: #161f38;
}

.gilroy-medium {
  font-family: 'GilroyMedium';
  font-size: 25px;
}
