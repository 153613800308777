element {
  --main-pdg: 2rem;
}

.header {
  width: calc(100vw - 6rem);
  position: absolute;
  z-index: 5;
  height: 3rem;
  padding: 2rem;
}

.header-content {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.logo {
  font-family: 'GilroyMedium';
  font-size: 30px;
  font-weight: 700;
  pointer-events: none;
  letter-spacing: -1px;
}
